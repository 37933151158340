<template functional>
  <vx-card>
    <p class="text-xl font-bold text-center py-6">
      Thank you for the feedback, {{ props.contact.firstName }}.
      We really appreciate it!
    </p>
  </vx-card>
</template>

<script>
export default {
  name: 'RjCompletedSurvey',

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },
  },
};
</script>
